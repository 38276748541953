import { CacheService } from './services/cache/cache.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { IonContent } from '@ionic/angular';
import { CookieService } from 'ngx-cookie-service';
import { AnalyticsService } from './services/analytics/analytics.service';
import { CookieManagerService } from './services/analytics/cookie-manager.service';
import { NavegacaoDTO } from './models/site/navegacaoDTO';
import { GeolocalizacaoService } from './services/geolocalizacao/geolocalizacao.service';
import { StorageService } from './services/storage/storage.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { LogService } from './services/log/log.service';
import { FunctionsService } from './services/common/functions.service';

interface coordenadas {

  latitude: number;
  longitude: number;
  cidade: string;
  pais: string;
  estado: string;
  bairro: string;

}

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent{

  
  coordenadas: coordenadas = {
    latitude: 0,
    longitude: 0,
    cidade: '',
    pais: '',
    estado: '',
    bairro: ''
  };



  constructor( private cacheService: CacheService
             , private router: Router
             , private analyticsService: AnalyticsService
             , private cookieManager: CookieManagerService 
             , private geoService: GeolocalizacaoService
             , private storageService: StorageService
             , private deviceService: DeviceDetectorService
             , private functionsService: FunctionsService ) {


    //atualiza o cache do browser
    this.cacheService.checkForUpdates();

    //return;

    this.coordenadas = this.storageService.getGeolocalizacaoVisitante();
      
      if(!this.coordenadas || 
         !this.coordenadas.latitude || 
         !this.coordenadas.longitude || 
         !this.coordenadas.estado || 
         !this.coordenadas.cidade || 
         !this.coordenadas.bairro){

        this.geoService.getGeolocalizacao().subscribe({
          next: (resp) => {

            this.coordenadas = {
              latitude: 0,
              longitude: 0,
              cidade: '',
              pais: '',
              estado: '',
              bairro: ''
            };
  
            this.functionsService.insertLogErro('navegacao (coords)',this.cookieManager.getSessionId(),resp);

            this.coordenadas.latitude = resp?.lat;
            this.coordenadas.longitude = resp?.lon;
            this.coordenadas.cidade = resp?.address?.city;
            this.coordenadas.pais = resp?.address?.country;
            this.coordenadas.estado = resp?.address?.state;
            this.coordenadas.bairro = resp?.address?.suburb;  
            
            this.storageService.setGeolocalizacaoVisitante(this.coordenadas);
            
            
            this.registerAccessLog();
          },
          error: (error) => {
            this.functionsService.insertLogErro('navegacao (erro)',this.cookieManager.getSessionId(),error);

            this.registerAccessLog();
          }
        });
  
      }else{
        this.functionsService.insertLogErro('navegacao (else)',this.cookieManager.getSessionId(),this.coordenadas);

        this.registerAccessLog();
      }
    
    
  }

  
  registerAccessLog(){

    this.router.events.subscribe(event => {
          
          
      if (event instanceof NavigationEnd && event.url && !event.url.includes('modulo-gc')) {
        
        const now: Date = new Date();

        let navegacao = new NavegacaoDTO();
        navegacao.url = event.url;
        navegacao.data = now;
        navegacao.latitude = this.coordenadas?.latitude;
        navegacao.longitude = this.coordenadas?.longitude;
        navegacao.sessao = this.cookieManager.getSessionId();
        navegacao.pais = this.coordenadas?.pais;
        navegacao.estado = this.coordenadas?.estado;
        navegacao.cidade = this.coordenadas?.cidade;
        navegacao.bairro = this.coordenadas?.bairro;
        navegacao.browser = this.deviceService.getDeviceInfo().browser;
        navegacao.versaoBrowser = this.deviceService.getDeviceInfo().browser_version;
        navegacao.equipamento = this.deviceService.getDeviceInfo().device;
        navegacao.tipoEquipamento = this.deviceService.getDeviceInfo().deviceType;
        navegacao.so = this.deviceService.getDeviceInfo().os;
        navegacao.versaoSO = this.deviceService.getDeviceInfo().os_version;
        navegacao.isMobile = this.deviceService.isMobile();
        navegacao.isTablet = this.deviceService.isTablet();
        navegacao.isDesktopDevice = this.deviceService.isDesktop();

        this.cookieManager.setLastVisitedPage(event.url, now.getTime());
        this.analyticsService.insertNavigation(navegacao).subscribe({
          next:(resp)=>{
            //console.log(resp);
          },
          error:(error)=>{
            console.log(error)
          }
        });
      }
    });

  }
  

}
