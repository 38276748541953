import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class DateFunctionsService {

  constructor() { }

  getFilterEndDate(endDate: Date) {
    if (!endDate) {
      return new Date('2100-01-01 00:00:00');
    }

    let convertedDate = moment(endDate).toDate();
    convertedDate.setHours(23, 59, 59);

    return convertedDate;
  }

  getFilterStartDate(startDate: Date) {
    if (!startDate) {
      return new Date('2000-01-01 00:00:00');
    }

    return moment(startDate).toDate();
  }

  getDateBrPattern(data: Date): string {
    data = new Date(data);

    let dia = data.getDate().toString(),
      diaF = (dia.length == 1) ? '0' + dia : dia,
      mes = (data.getMonth() + 1).toString(), //+1 pois no getMonth Janeiro começa com zero.
      mesF = (mes.length == 1) ? '0' + mes : mes,
      anoF = data.getFullYear();

    return diaF + "/" + mesF + "/" + anoF;
  }

  getDateHourBrPattern(data: Date): string {
    data = new Date(data);

    let dia = data.getDate().toString(),
      diaF = (dia.length == 1) ? '0' + dia : dia,
      mes = (data.getMonth() + 1).toString(), //+1 pois no getMonth Janeiro começa com zero.
      mesF = (mes.length == 1) ? '0' + mes : mes,
      anoF = data.getFullYear(),
      hora = data.getHours().toString(),
      horaF = (hora.length == 1) ? '0' + hora : hora,
      min = data.getMinutes().toString(),
      minF = (min.length == 1) ? '0' + min : min;

    return diaF + "/" + mesF + "/" + anoF + ' ' + horaF + ':' + minF;
  }

  getHourBrPattern(data: Date): string {
    data = new Date(data);
    let hora = data.getHours().toString(),
      horaF = (hora.length == 1) ? '0' + hora : hora,
      min = data.getMinutes().toString(),
      minF = (min.length == 1) ? '0' + min : min;

    return horaF + ':' + minF;
  }

  addBusinessDays(startDate, numDays) {
    let currentDate = new Date(startDate);

    while (numDays > 0) {
      // Adiciona um dia ao currentDate.
      currentDate.setDate(currentDate.getDate() + 1);

      // Verifica se o dia da semana de currentDate é sábado (6) ou domingo (0).
      if (currentDate.getDay() !== 0 && currentDate.getDay() !== 6) {
        numDays--;
      }
    }

    return currentDate;
  }

  formatDateToYMD(date: Date): string {
    return moment(date).format('YYYY-MM-DD');
  }

  parseDate(dateString: string): Date {
    const [year, month, day] = dateString.split('-').map(Number);
    return new Date(year, month - 1, day); // Month is zero-indexed in JavaScript Date
  }
}
