import { AuthGuard } from './security/AuthGuard';
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'detalhar-noticia',
    loadChildren: () => import('./pages/detalhar-noticia/detalhar-noticia.module').then( m => m.DetalharNoticiaPageModule)
  },
  {
    path: 'noticia/:id/:titulo',
    loadChildren: () => import('./pages/detalhar-noticia/detalhar-noticia.module').then( m => m.DetalharNoticiaPageModule)
  },
  {
    path: 'NOTICIA/:id/:titulo',
    loadChildren: () => import('./pages/detalhar-noticia/detalhar-noticia.module').then( m => m.DetalharNoticiaPageModule)
  },
  {
    path: 'listar-noticias',
    loadChildren: () => import('./pages/listar-noticias/listar-noticias.module').then( m => m.ListarNoticiasPageModule)
  },
  {
    path: 'listar_noticias',
    loadChildren: () => import('./pages/listar-noticias/listar-noticias.module').then( m => m.ListarNoticiasPageModule)
  }, 
  
  {
    path: 'tipos-carrossel',
    loadChildren: () => import('./pages/tipos-carrossel/tipos-carrossel.module').then( m => m.TiposCarrosselPageModule)
  },
  
  

  /*GC HOME*/
  {
    path: 'modulo-gc',
    redirectTo: 'modulo-gc/gc-funcoes',
    pathMatch: 'full'
  },

  {
    path: 'modulo-gc/gc-login',
    loadChildren: () => import('./pages/modulo-gc/controle-acesso/gc-login/gc-login.module').then( m => m.GcLoginPageModule)
  },
  {
    path: 'modulo-gc/gc-recuperar-senha',
    loadChildren: () => import('./pages/modulo-gc/controle-acesso/gc-recuperar-senha/gc-recuperar-senha.module').then( m => m.GcRecuperarSenhaPageModule)
  },
  {
    path: 'modulo-gc/gc-alterar-senha',
    loadChildren: () => import('./pages/modulo-gc/controle-acesso/gc-alterar-senha/gc-alterar-senha.module').then( m => m.GcAlterarSenhaPageModule)
  },
  {
    path: 'modulo-gc/gc-cadastrar-noticia',
    loadChildren: () => import('./pages/modulo-gc/site/gc-cadastrar-noticia/gc-cadastrar-noticia.module').then( m => m.GcCadastrarNoticiaPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'modulo-gc/gc-listar-noticias',
    loadChildren: () => import('./pages/modulo-gc/site/gc-listar-noticias/gc-listar-noticias.module').then( m => m.GcListarNoticiasPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'modulo-gc/gc-home',
    loadChildren: () => import('./pages/modulo-gc/site/gc-home/gc-home.module').then( m => m.GcHomePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'modulo-gc/gc-funcoes',
    loadChildren: () => import('./pages/modulo-gc/gc-funcoes/gc-funcoes.module').then( m => m.GcFuncoesPageModule)
  },
  {
    path: 'modulo-gc/gc-cadastrar-habilitacao',
    loadChildren: () => import('./pages/modulo-gc/curso/gc-cadastrar-habilitacao/gc-cadastrar-habilitacao.module').then( m => m.GcCadastrarHabilitacaoPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'modulo-gc/gc-cadastrar-formacao',
    loadChildren: () => import('./pages/modulo-gc/curso/gc-cadastrar-formacao/gc-cadastrar-formacao.module').then( m => m.GcCadastrarFormacaoPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'modulo-gc/gc-cadastrar-modalidade',
    loadChildren: () => import('./pages/modulo-gc/curso/gc-cadastrar-modalidade/gc-cadastrar-modalidade.module').then( m => m.GcCadastrarModalidadePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'modulo-gc/gc-cadastrar-turno',
    loadChildren: () => import('./pages/modulo-gc/curso/gc-cadastrar-turno/gc-cadastrar-turno.module').then( m => m.GcCadastrarTurnoPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'modulo-gc/gc-cadastrar-curso',
    loadChildren: () => import('./pages/modulo-gc/curso/gc-cadastrar-curso/gc-cadastrar-curso.module').then( m => m.GcCadastrarCursoPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'modulo-gc/gc-listar-cursos',
    loadChildren: () => import('./pages/modulo-gc/curso/gc-listar-cursos/gc-listar-cursos.module').then( m => m.GcListarCursosPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'modulo-gc/gc-adm-tipos-pagamento',
    loadChildren: () => import('./pages/modulo-gc/financeiro/gc-adm-tipos-pagamento/gc-adm-tipos-pagamento.module').then( m => m.GcAdmTiposPagamentoPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'modulo-gc/gc-listar-pagamentos',
    loadChildren: () => import('./pages/modulo-gc/financeiro/gc-listar-pagamentos/gc-listar-pagamentos.module').then( m => m.GcListarPagamentosPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'modulo-gc/gc-listar-agrupamentos-cursos',
    loadChildren: () => import('./pages/modulo-gc/curso/gc-listar-agrupamentos-cursos/gc-listar-agrupamentos-cursos.module').then( m => m.GcListarAgrupamentosCursosPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'modulo-gc/gc-cadastrar-area-conhecimento',
    loadChildren: () => import('./pages/modulo-gc/curso/gc-cadastrar-area-conhecimento/gc-cadastrar-area-conhecimento.module').then( m => m.GcCadastrarAreaConhecimentoPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'modulo-gc/gc-agrupar-cursos',
    loadChildren: () => import('./pages/modulo-gc/curso/gc-agrupar-cursos/gc-agrupar-cursos.module').then( m => m.GcAgruparCursosPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'modulo-gc/gc-cadastrar-banner',
    loadChildren: () => import('./pages/modulo-gc/curso/gc-cadastrar-banner/gc-cadastrar-banner.module').then( m => m.GcCadastrarBannerPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'modulo-gc/gc-adm-templates-email',
    loadChildren: () => import('./pages/modulo-gc/comunicacao/gc-adm-templates-email/gc-adm-templates-email.module').then( m => m.GcAdmTemplatesEmailPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'modulo-gc/gc-listar-templates-email',
    loadChildren: () => import('./pages/modulo-gc/comunicacao/gc-listar-templates-email/gc-listar-templates-email.module').then( m => m.GcListarTemplatesEmailPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'modulo-gc/gc-listar-candidatos',
    loadChildren: () => import('./pages/modulo-gc/vestibular/gc-listar-candidatos/gc-listar-candidatos.module').then( m => m.GcListarCandidatosPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'modulo-gc/gc-editar-candidato',
    loadChildren: () => import('./pages/modulo-gc/vestibular/gc-editar-candidato/gc-editar-candidato.module').then( m => m.GcEditarCandidatoPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'modulo-gc/gc-listar-mensagens',
    loadChildren: () => import('./pages/modulo-gc/comunicacao/gc-listar-mensagens/gc-listar-mensagens.module').then( m => m.GcListarMensagensPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'modulo-gc/gc-listar-salas',
    loadChildren: () => import('./pages/modulo-gc/vestibular/gc-listar-salas/gc-listar-salas.module').then( m => m.GcListarSalasPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'modulo-gc/gc-cadastrar-sala',
    loadChildren: () => import('./pages/modulo-gc/vestibular/gc-cadastrar-sala/gc-cadastrar-sala.module').then( m => m.GcCadastrarSalaPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'modulo-gc/gc-cadastrar-sistema',
    loadChildren: () => import('./pages/modulo-gc/controle-acesso/gc-cadastrar-sistema/gc-cadastrar-sistema.module').then( m => m.GcCadastrarSistemaPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'modulo-gc/gc-cadastrar-funcionalidade',
    loadChildren: () => import('./pages/modulo-gc/controle-acesso/gc-cadastrar-funcionalidade/gc-cadastrar-funcionalidade.module').then( m => m.GcCadastrarFuncionalidadePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'modulo-gc/gc-listar-grupos-funcionalidades',
    loadChildren: () => import('./pages/modulo-gc/controle-acesso/gc-listar-grupos-funcionalidades/gc-listar-grupos-funcionalidades.module').then( m => m.GcListarGruposFuncionalidadesPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'modulo-gc/gc-cadastrar-grupo-funcionalidades',
    loadChildren: () => import('./pages/modulo-gc/controle-acesso/gc-cadastrar-grupo-funcionalidades/gc-cadastrar-grupo-funcionalidades.module').then( m => m.GcCadastrarGrupoFuncionalidadesPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'modulo-gc/gc-cadastrar-parametro-dossie',
    loadChildren: () => import('./pages/modulo-gc/documentacao/gc-cadastrar-parametro-dossie/gc-cadastrar-parametro-dossie.module').then( m => m.GcCadastrarParametroDossiePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'modulo-gc/gc-cadastrar-parametro-analise-documentacao',
    loadChildren: () => import('./pages/modulo-gc/documentacao/gc-cadastrar-parametro-analise-documentacao/gc-cadastrar-parametro-analise-documentacao.module').then( m => m.GcCadastrarParametroAnaliseDocumentacaoPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'modulo-gc/gc-detalhar-parametro-analise-documentacao',
    loadChildren: () => import('./pages/modulo-gc/documentacao/gc-detalhar-parametro-analise-documentacao/gc-detalhar-parametro-analise-documentacao.module').then( m => m.GcDetalharParametroAnaliseDocumentacaoPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'modulo-gc/gc-listar-parametros-analise-documentacao',
    loadChildren: () => import('./pages/modulo-gc/documentacao/gc-listar-parametros-analise-documentacao/gc-listar-parametros-analise-documentacao.module').then( m => m.GcListarParametrosAnaliseDocumentacaoPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'modulo-gc/gc-analisar-documentacao-inscricao',
    loadChildren: () => import('./pages/modulo-gc/documentacao/gc-analisar-documentacao-inscricao/gc-analisar-documentacao-inscricao.module').then( m => m.GcAnalisarDocumentacaoInscricaoPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'modulo-gc/gc-analisar-documentacao-matricula',
    loadChildren: () => import('./pages/modulo-gc/documentacao/gc-analisar-documentacao-matricula/gc-analisar-documentacao-matricula.module').then( m => m.GcAnalisarDocumentacaoMatriculaPageModule),
    canActivate: [AuthGuard] 
  },
  {
    path: 'modulo-gc/gc-importar-planilha-classificacao',
    loadChildren: () => import('./pages/modulo-gc/vestibular/gc-importar-planilha-classificacao/gc-importar-planilha-classificacao.module').then( m => m.GcImportarPlanilhaClassificacaoPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'modulo-gc/gc-listar-configuracoes-template-email',
    loadChildren: () => import('./pages/modulo-gc/comunicacao/gc-listar-configuracoes-template-email/gc-listar-configuracoes-template-email.module').then( m => m.GcListarConfiguracoesTemplateEmailPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'modulo-gc/gc-cadastrar-configuracao-template-email',
    loadChildren: () => import('./pages/modulo-gc/comunicacao/gc-cadastrar-configuracao-template-email/gc-cadastrar-configuracao-template-email.module').then( m => m.GcCadastrarConfiguracaoTemplateEmailPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'modulo-gc/gc-adm-menu',
    loadChildren: () => import('./pages/modulo-gc/site/gc-adm-menu/gc-adm-menu.module').then( m => m.GcAdmMenuPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'modulo-gc/gc-cadastrar-pagina',
    loadChildren: () => import('./pages/modulo-gc/site/gc-cadastrar-pagina/gc-cadastrar-pagina.module').then( m => m.GcCadastrarPaginaPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'modulo-gc/gc-listar-paginas',
    loadChildren: () => import('./pages/modulo-gc/site/gc-listar-paginas/gc-listar-paginas.module').then( m => m.GcListarPaginasPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'modulo-gc/gc-cadastrar-vestibular',
    loadChildren: () => import('./pages/modulo-gc/vestibular/gc-cadastrar-vestibular/gc-cadastrar-vestibular.module').then( m => m.GcCadastrarVestibularPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'modulo-gc/gc-agendar-graduacao',
    loadChildren: () => import('./pages/modulo-gc/vestibular/gc-agendar-graduacao/gc-agendar-graduacao.module').then( m => m.GcAgendarGraduacaoPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'modulo-gc/gc-agendar-pos-graduacao',
    loadChildren: () => import('./pages/modulo-gc/vestibular/gc-agendar-pos-graduacao/gc-agendar-pos-graduacao.module').then( m => m.GcAgendarPosGraduacaoPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'modulo-gc/gc-agendar-extensao',
    loadChildren: () => import('./pages/modulo-gc/vestibular/gc-agendar-extensao/gc-agendar-extensao.module').then( m => m.GcAgendarExtensaoPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'modulo-gc/gc-listar-vestibulares',
    loadChildren: () => import('./pages/modulo-gc/vestibular/gc-listar-vestibulares/gc-listar-vestibulares.module').then( m => m.GcListarVestibularesPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'modulo-gc/gc-classificar-candidatos',
    loadChildren: () => import('./pages/modulo-gc/vestibular/gc-classificar-candidatos/gc-classificar-candidatos.module').then( m => m.GcClassificarCandidatosPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'modulo-gc/gc-listar-logs',
    loadChildren: () => import('./pages/modulo-gc/logs/gc-listar-logs/gc-listar-logs.module').then( m => m.GcListarLogsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'modulo-gc/update-moodle',
    loadChildren: () => import('./pages/modulo-gc/vestibular/update-moodle/update-moodle.module').then( m => m.UpdateMoodlePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'modulo-gc/gc-converter-pdf',
    loadChildren: () => import('./pages/modulo-gc/documentacao/gc-converter-pdf/gc-converter-pdf.module').then( m => m.GcConverterPdfPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'modulo-gc/gc-analytics',
    loadChildren: () => import('./pages/modulo-gc/site/gc-analytics/gc-analytics.module').then( m => m.GcAnalyticsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'modulo-gc/gc-cadastrar-mensagem-cookie',
    loadChildren: () => import('./pages/modulo-gc/site/gc-cadastrar-mensagem-cookie/gc-cadastrar-mensagem-cookie.module').then( m => m.GcCadastrarMensagemCookiePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'detalhar-curso',
    loadChildren: () => import('./pages/detalhar-curso/detalhar-curso.module').then( m => m.DetalharCursoPageModule)
  },
  {
    path: 'curso/:id/:habilitarInscricao/:formacao/:habilitacao/:modalidade/:nome',
    loadChildren: () => import('./pages/detalhar-curso/detalhar-curso.module').then( m => m.DetalharCursoPageModule)
  },
  {
    path: 'CURSO/:id/:habilitarInscricao/:formacao/:habilitacao/:modalidade/:nome',
    loadChildren: () => import('./pages/detalhar-curso/detalhar-curso.module').then( m => m.DetalharCursoPageModule)
  },

  {
    path: 'exibir-edital',
    loadChildren: () => import('./pages/exibir-edital/exibir-edital.module').then( m => m.ExibirEditalPageModule)
  },

  {
    path: 'listar-cursos',
    loadChildren: () => import('./pages/listar-cursos/listar-cursos.module').then( m => m.ListarCursosPageModule)
  },
  {
    path: 'cursos/:idFormacao/:formacao',
    loadChildren: () => import('./pages/listar-cursos/listar-cursos.module').then( m => m.ListarCursosPageModule)
  },
  {
    path: 'CURSOS/:idFormacao/:formacao',
    loadChildren: () => import('./pages/listar-cursos/listar-cursos.module').then( m => m.ListarCursosPageModule)
  },

  {
    path: 'enviar-mensagem',
    loadChildren: () => import('./pages/enviar-mensagem/enviar-mensagem.module').then( m => m.EnviarMensagemPageModule)
  },
  {
    path: 'enviar-mensagem/:idCurso/:nomeCurso',
    loadChildren: () => import('./pages/enviar-mensagem/enviar-mensagem.module').then( m => m.EnviarMensagemPageModule)
  },
  {
    path: 'politica-privacidade',
    loadChildren: () => import('./pages/politica-privacidade/politica-privacidade.module').then( m => m.PoliticaPrivacidadePageModule)
  },
  {
    path: 'pages/:nome',
    loadChildren: () => import('./pages/detalhar-pagina/detalhar-pagina.module').then( m => m.DetalharPaginaPageModule)
  },  
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
