import { PdfViewerModule } from 'ng2-pdf-viewer';
import { AuthGuard } from './security/AuthGuard';
import { HttpConfigInterceptor } from './interceptors/httpconfig.interceptor';
import { ComponentsModule } from './components/components.module';
import { DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { registerLocaleData } from '@angular/common';
import localept from '@angular/common/locales/pt';
import { CookieService } from 'ngx-cookie-service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { GeolocalizacaoService } from './services/geolocalizacao/geolocalizacao.service';

registerLocaleData(localept, 'pt');



@NgModule({
  
  declarations: [AppComponent],
  entryComponents: [],
  
  imports: [ BrowserModule
           , IonicModule.forRoot()
           , AppRoutingModule
           , ComponentsModule
           , HttpClientModule
           , PdfViewerModule
           , ServiceWorkerModule.register('ngsw-worker.js', {
              enabled: environment.production,
              // Register the ServiceWorker as soon as the app is stable
              // or after 30 seconds (whichever comes first).
              registrationStrategy: 'registerWhenStable:30000'
            }),
            BrowserAnimationsModule ,     
           ],
  
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: LOCALE_ID, useValue: 'pt' },
    { provide:  DEFAULT_CURRENCY_CODE, useValue: 'BRL' },
    { provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true },
    { provide: AuthGuard},
    { provide: CookieService },
    { provide: GeolocalizacaoService}      
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
