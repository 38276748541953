import { Component, OnInit } from '@angular/core';
import { url } from 'inspector';
import { CookieService } from 'ngx-cookie-service';
import { ConfiguracaoCookieDTO } from 'src/app/models/site/configracaoCookieDTO';
import { CookieManagerService } from 'src/app/services/analytics/cookie-manager.service';
import { FunctionsService } from 'src/app/services/common/functions.service';
import { ConfiguracaoCookieService } from 'src/app/services/configuracao-cookie/configuracao-cookie.service';
import { StorageService } from 'src/app/services/storage/storage.service';

@Component({
  selector: 'app-cookies-consent',
  templateUrl: './cookies-consent.component.html',
  styleUrls: ['./cookies-consent.component.scss'],
})
export class CookiesConsentComponent implements OnInit {

  previewing = false;
  visivel = false;
  configuracaoCookie: ConfiguracaoCookieDTO = new ConfiguracaoCookieDTO();
  
  
  constructor( private configuracaoCookieService: ConfiguracaoCookieService
             , private functionsService: FunctionsService
             , private cookieManagerService: CookieManagerService
             , private storageService: StorageService ) {

    
    this.configuracaoCookieService.findAll().subscribe({
      next:(resp)=>{
        
        if(resp.length>0){
          this.configuracaoCookie = resp[0];
        }        
        
      },
      error:(error)=>{
        console.log(error);
      }
    });

    const usuarioLogado = this.storageService.getUsuarioLogado();

    if(window.location.href.includes('/modulo-gc/')){
      this.visivel = false;
    }else if(usuarioLogado && usuarioLogado.dataAceiteCookies){
      this.visivel = false;
    }else{
      if(this.cookieManagerService.getAcceptCookies()){
        this.visivel = false;
      }else if(sessionStorage.getItem('cookies-refused')){//já negou nesta navegação
        this.visivel = false;
      }else{
        this.visivel = true;  
      }
    }
    
    
  }

  
  ngOnInit() {}

  

  close() {
    this.visivel = false;
    this.previewing = false;
  }

  open() {
    this.visivel = true;
  }

  preview(configuracaoCookie: ConfiguracaoCookieDTO){
    this.configuracaoCookie = configuracaoCookie;
    this.previewing = true;
    this.open();
  }

  openDocument(){
    this.functionsService.goToPath(this.configuracaoCookie.urlPoliticaPrivacidade, true);
  }

  accept(){
    this.cookieManagerService.setAcceptCookies(true);
    this.close();
  }

  deny(){
    this.cookieManagerService.setAcceptCookies(false);
    this.close();
  }
}
