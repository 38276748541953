import { StorageService } from './../storage/storage.service';
import { PERFIL } from './../../config/perfil.config';
import { UsuarioDTO } from '../../models/controle-acesso/usuarioDTO';
import { API_CONFIG } from 'src/app/config/api.config';
import { Observable } from 'rxjs/internal/Observable';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PermissaoAcessoService {

  constructor( private http: HttpClient
             , private storageService: StorageService ) { }

  canAccess(url: string) : Observable<boolean>{

    const usuario = this.storageService.getUsuarioLogado();
    if(usuario.perfil===PERFIL.MASTER){
      return of(true); 
    }

    //retirar parâmetros da url
    if(url.indexOf('?')>0){
      url = url.substring(0, url.indexOf('?'));
    }
    
    if(url.indexOf('http')<0){
      url = window.location.protocol + '//' + window.location.host + url;
    }
    return this.http.get<boolean>( API_CONFIG.apiURL+'/permissoesacesso/canAccess?idUsuario='+usuario.id+'&urlFuncionalidade=' + url);

  }


  canRemove(url: string) : Observable<boolean> {

    const usuario = this.storageService.getUsuarioLogado();
    if(usuario.perfil===PERFIL.MASTER){
      return of(true); 
    }

    return this.http.get<boolean>( API_CONFIG.apiURL+'/permissoesacesso/canRemove?idUsuario='+usuario.id+'&urlFuncionalidade=' + url);

  }


  canCreateAndEdit(url: string) : Observable<boolean> {

    const usuario = this.storageService.getUsuarioLogado();
    if(usuario.perfil===PERFIL.MASTER){
      return of(true); 
    }

    //retirar parâmetros da url
    if(url.indexOf('?')>0){
      url = url.substring(0, url.indexOf('?'));
    }
    
    return this.http.get<boolean>( API_CONFIG.apiURL+'/permissoesacesso/canCreateAndEdit?idUsuario='+usuario.id+'&urlFuncionalidade=' + url);

  }

  canPublish(url: string) : Observable<boolean> {

    const usuario = this.storageService.getUsuarioLogado();
    if(usuario.perfil===PERFIL.MASTER){
      return of(true); 
    }

    //retirar parâmetros da url
    if(url.indexOf('?')>0){
      url = url.substring(0, url.indexOf('?'));
    }
    
    return this.http.get<boolean>( API_CONFIG.apiURL+'/permissoesacesso/canPublish?idUsuario='+usuario.id+'&urlFuncionalidade=' + url);

  }

}
